.base-card {
  padding: 0 0 36px;

  @media ($over-screen-medium) {
    width: 33.333%;
    padding-right: 24px;
    padding-left: 0;

    &:nth-child(3n+2) {
      padding-right: 12px;
      padding-left: 12px;
    }

    &:nth-child(3n+3) {
      padding-right: 0;
      padding-left: 24px;
    }
  }

  @media ($under-screen-medium) and ($over-screen-xsmall) {
    width: 50%;
    padding-right: 13px;
    padding-left: 0;

    &:nth-child(2n+2) {
      padding-right: 0;
      padding-left: 13px;
    }
  }

  @media ($under-screen-xsmall) {
    width: 100%;
    padding-right: 0;
    padding-left: 0;
  }

  &__inner {
    box-shadow: $shadow-default;
  }

  &__header {
    width: 100%;
    background-size: cover;
    background-position: 50%;
    cursor: pointer;
    height: 140px;

    @media ($under-screen-xsmall) {
      height: 100px;
    }
  }

  &__content {
    padding: 20px 25px;
    height: 210px;
    position: relative;
    overflow: hidden;

    @media ($under-screen-xsmall) {
      height: 200px;
      padding: 15px 25px;
    }
  }

  &__title {
    @include title-medium;
    margin-bottom: 3px;
    cursor: pointer;
    
    // all cards except discover cards
    a {
      @include title-medium;
      position: relative;
      display: block;
      overflow: hidden;
      max-height: $font-line-height-m * 2;
    }
  }

  &__title--multiline {
    // Only for communities search and discover cards
    a::after {
      @include fade-ellipsis($font-line-height-m);
    }
  }

  &__subtitle {
    margin-bottom: 4px;

    a {
      @include text-small;
      color: $color-text-light;
    }

    @media ($under-screen-xsmall) {
      margin-bottom: 0;
    }
  }

  &__body {
    @include text;
    cursor: pointer;
    margin-top: 5px;
    position: relative;
    overflow: hidden;
    max-height: $font-line-height-s * 3;

    &::after {
      @include fade-ellipsis($font-line-height-s);
    }

    a {
      @include text;
      display: block;
    }
  }

  &__cta {
    display: block;
    @include text-link-primary;
    position: relative;
    line-height: $font-line-height-s-wide;
  }

  &__footer {
    position: absolute;
    background-color: $color-white;
    left: 25px;
    right: 25px;
    bottom: 0;
  }
}

.base-card-wider {
  width: 50%;
  padding: 0 13px 36px 0;

  @media ($over-screen-xsmall) {
    &:nth-child(2n+2) {
      padding-right: 0;
      padding-left: 13px;
    }
  }

  @media ($under-screen-xsmall) {
    width: 100%;
    padding-right: 0;
    padding-left: 0;
  }
}
