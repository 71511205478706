.tag {
  display: inline-block;
  background: $color-blue-background;
  border: $grey-line;
  padding: 6px 12px;
  margin-right: 10px;
  margin-bottom: 10px;
  font-size: 15px;
  text-transform: lowercase;
  border-radius: 4px;
  cursor: text;
  text-decoration: none;
  color: $color-black;
}
