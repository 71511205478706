.search {
  &__summary-row {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 5px;
    height: 30px;
    @media ($under-screen-small) {
      padding: 0 20px;
    }

    .mobile-filter-modal {
      @media ($over-screen-small) {
        display: none;
      }
    }
  }

  &__no-results {
    display: flex;
    flex-direction: column;

    .no-results-summary * {
      padding-bottom: 10px;
    }

    .no-results-count {
      @media ($under-screen-small) {
        border-bottom: $grey-line;
        margin-bottom: 20px;
      }
    }

    .query-text {
      font-weight: bold;
      padding: 0;
    }
  }

  &__sidebar {
    @media ($under-screen-small) {
      display: none;
    }
  }
}

.filter-options {

  .modal-content {
    padding: 22px 12px 12px 12px;
  }

  .btn-wrapper {
    display: flex;

    button {
      flex: 1;
    }
  }

  .filter-header {
    display: flex;
    border-bottom: $grey-line;
    padding-bottom: 10px;

    .filter-title {
      @include title-medium;
      flex-grow: 1;
    }
  }

  .filter-section-title {
    @include title-small;
  }
}
