/* Needed for navigator pages and src/utils/parserHTML.tsx
// Please do not use otherwise, use the src/components/buttons/button.tsx component or
// styled components in styles-js/buttons.ts instead.
*/

.btn {
  @include text;
  margin: 5px 5px 5px 0;
  padding: 6px 12px;
  background-color: transparent;
  border: 1px solid transparent;
  border-radius: 0.25rem;
  transition: all 0.7s ease;
  white-space: nowrap;
  text-align: center;
  user-select: none;

  &:hover {
    color: $color-text-black;
    text-decoration: none;
  }

  &-primary {
    color: $color-button-primary-text;

    &:disabled {
      &, &:hover {
        background: $color-grey-light;
        border: $grey-line;
        color: $color-black;
      }
    }
  }
}
