// Base colours

$color-black: #333333;
$color-black-dark: #191919;
$color-black-light: #525252;
$color-grey-dark: #767676; // text, icon
$color-grey: #AAAAAA;
$color-grey-light: #E3E3E3; // border, underline
$color-white: #FFFFFF;

// Brand colours

$color-green: #2DA46D;
$color-green-darker: #12422C;
$color-green-dark: #1F734C;
$color-green-light: #89DFB7;
$color-green-lighter: #D0F2E2;

$color-blue: #1671C0;
$color-blue-darker: #092D4D;
$color-blue-dark: #0F4F86;
$color-blue-light: #7AB9F0;
$color-blue-lighter: #CAE3F9;
$color-blue-background: #F5F6FA;

$color-yellow: #F5B83D;
$color-yellow-dark: #745006;
$color-yellow-light: #FDF1D8;

$color-pink: #E75D77;
$color-pink-darker: #711123;
$color-pink-dark: #C61D3D;
$color-pink-light: #F3AEBB;
$color-pink-lighter: #FADFE4;

$color-purple: #812FA8;
$color-purple-darker: #341343;
$color-purple-dark: #5A2176;
$color-purple-light: #C48CDF;
$color-purple-lighter: #E7D1F2;

$color-orange: #FC9536;
$color-orange-darker: #A45002;
$color-orange-dark: #EB7203;
$color-orange-light: #FDB879;
$color-orange-lighter: #FEE3C9;

// Alert colours

$color-success: $color-green-darker;
$color-success-background: $color-green-lighter;
$color-warning: $color-yellow-dark;
$color-error: $color-pink-darker;
$color-error-background: $color-pink-lighter;
$color-danger: $color-pink-dark;
$color-danger-hover: $color-pink-darker;

// Social media colours

$color-facebook: #4267B2;
$color-twitter: #1DA1F2;
$color-instagram: #E1306C;
$color-youtube: #FF0000;


// Text

$color-text-black: $color-black;
$color-text-light: $color-grey-dark;
$color-text-white: $color-white;
$color-text-link-primary: $color-blue;
$color-text-link-primary-hover: $color-blue-dark;
$color-text-link-secondary: $color-grey-dark;


// Buttons

$color-button-primary: $color-blue;
$color-button-primary-hover: $color-blue-dark;
$color-button-primary-text: $color-white;

$color-button-secondary: $color-blue;
$color-button-secondary-hover: $color-blue-dark;

$color-button-tag: $color-green-lighter;
$color-button-tag-hover: $color-green-light;

$color-button-focus: rgba($color-blue, 0.25);


// Transparent colours

$color-white-80: rgba($color-white, 0.8);
$color-transparent: rgba($color-white, 0);
$color-shadow: rgba(51, 51, 51, 0.2);


:export {
  // Base colours
  colorBlack: $color-black;
  colorBlackDark: $color-black-dark;
  colorBlackLight: $color-black-light;
  colorGreyDark: $color-grey-dark;
  colorGrey: $color-grey;
  colorGreyLight: $color-grey-light;
  colorWhite: $color-white;

  // Brand colours
  colorGreen: $color-green;
  colorGreenDarker: $color-green-darker;
  colorGreenDark: $color-green-dark;
  colorGreenLight: $color-green-light;
  colorGreenLighter: $color-green-lighter;
  colorBlue: $color-blue;
  colorBlueDarker: $color-blue-darker;
  colorBlueDark: $color-blue-dark;
  colorBlueLight: $color-blue-light;
  colorBlueLighter: $color-blue-lighter;
  colorBlueBackground: $color-blue-background;
  colorYellow: $color-yellow;
  colorYellowDark: $color-yellow-dark;
  colorYellowLight: $color-yellow-light;
  colorPink: $color-pink;
  colorPinkDarker: $color-pink-darker;
  colorPinkDark: $color-pink-dark;
  colorPinkLight: $color-pink-light;
  colorPinkLighter: $color-pink-lighter;
  colorPurple: $color-purple;
  colorPurpleDarker: $color-purple-darker;
  colorPurpleDark: $color-purple-dark;
  colorPurpleLight: $color-purple-light;
  colorPurpleLighter: $color-purple-lighter;
  colorOrange: $color-orange;
  colorOrangeDarker: $color-orange-darker;
  colorOrangeDark: $color-orange-dark;
  colorOrangeLight: $color-orange-light;
  colorOrangeLighter: $color-orange-lighter;

  // Alert colours
  colorSuccess: $color-success;
  colorSuccessBackground: $color-success-background;
  colorWarning: $color-warning;
  colorError: $color-error;
  colorErrorBackground: $color-error-background;
  colorDanger: $color-danger;
  colorDangerHover: $color-danger-hover;

  // Social media colours
  colorFacebook: $color-facebook;
  colorTwitter: $color-twitter;
  colorInstagram: $color-instagram;
  colorYoutube: $color-youtube;

  // Text
  colorTextBlack: $color-text-black;
  colorTextLight: $color-text-light;
  colorTextWhite: $color-text-white;
  colorTextLinkPrimary: $color-text-link-primary;
  colorTextLinkPrimaryHover: $color-text-link-primary-hover;
  colorTextLinkSecondary: $color-text-link-secondary;

  // Buttons
  colorButtonPrimary: $color-button-primary;
  colorButtonPrimaryHover: $color-button-primary-hover;
  colorButtonPrimaryText: $color-button-primary-text;

  colorButtonSecondary: $color-button-secondary;
  colorButtonSecondaryHover: $color-button-secondary-hover;

  colorButtonTag: $color-button-tag;
  colorButtonTagHover: $color-button-tag-hover;

  colorButtonFocus: $color-button-focus;

  // Transparent colours
  colorWhite80: $color-white-80;
  colorTransparent: $color-transparent;
  colorShadow: $color-shadow;
}
