$font-static-url: 'https://images.hu-production.be/static/fonts';
$icon-date: '21-03-2024';
/*
  Fontello (http://fontello.com)
*/
@font-face {
  font-family: 'fontello';
  src: url($font-static-url + '/icons-' + $icon-date + '/fontello.eot');
  src: url($font-static-url + '/icons-' + $icon-date + '/fontello.eot?#iefix') format('embedded-opentype'),
       url($font-static-url + '/icons-' + $icon-date + '/fontello.woff2') format('woff2'),
       url($font-static-url + '/icons-' + $icon-date + '/fontello.woff') format('woff'),
       url($font-static-url + '/icons-' + $icon-date + '/fontello.ttf') format('truetype'),
       url($font-static-url + '/icons-' + $icon-date + '/fontello.svg#fontello') format('svg');
  font-weight: normal;
  font-style: normal;
}

 [class^="icon-"]:before, [class*=" icon-"]:before {
  font-family: "fontello";
  font-style: normal;
  font-weight: normal;
  speak: none;
  font-size: 1em;

  display: inline-block;
  text-decoration: inherit;
  width: 1em;
  margin-right: .5em;
  text-align: center;
  /* opacity: .8; */

  /* For safety - reset parent styles, that can break glyph codes*/
  font-variant: normal;
  text-transform: none;

  /* fix buttons height, for twitter bootstrap
  line-height: 1em; */

  /* you can be more comfortable with increased icons size */
  /* font-size: 120%; */

  /* Font smoothing. That was taken from TWBS */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

// In hovered anchors we don't want the underline on icons
[class^="icon-"]:hover:before, [class*=" icon-"]:hover:before {
  text-decoration: none;
}

// Empty icons don't need a margin (just for display purposes not prepending text)
[class^="icon-"]:empty:before, [class*=" icon-"]:empty:before, .icon:empty, .icon-empty:before {
  margin-right: 0;
}

// Sub icons can be a little less obvious
.sub-icon {
  opacity: 0.75;
}

// Font Awesome v4
.icon-at:before { content: '\f1fa'; } /* '' */
.icon-bold:before { content: '\e800'; } /* '' */
.icon-facebook:before { content: '\e8f1'; } /* '' */
.icon-instagram:before { content: '\e8f4'; } /* '' */
.icon-italic:before { content: '\e801'; } /* '' */
.icon-like-filled:before { content: '\e8d3'; } /* '' */
.icon-like-outline:before { content: '\e8d2'; } /* '' */
.icon-link-ext:before { content: '\e8e7'; } /* '' */
.icon-lock:before { content: '\e8e6'; } /* '' */
.icon-mail:before { content: '\e802'; } /* '' */
.icon-picture:before { content: '\e8d9'; } /* '' */
.icon-search:before { content: '\e8b6'; } /* '' */
.icon-smile:before { content: '\f118'; } /* '' */
.icon-thumbs-up-filled:before { content: '\e8d1'; } /* '' */
.icon-thumbs-up:before { content: '\e8c9'; } /* '' */
.icon-twitter:before { content: '\e8f2'; } /* '' */
.icon-underline:before { content: '\f0cd'; } /* '' */
.icon-youtube:before { content: '\e8f3'; } /* '' */
.icon-zoom-in:before { content: '\e804'; } /* '' */

// Custom
.icon-alert:before { content: '\e8e4'; } /* '' */
.icon-cross:before { content: '\e8a9'; } /* '' */
.icon-help:before { content: '\e8d8'; } /* '' */
.icon-in-reply:before { content: '\e8e5'; } /* '' */
.icon-information:before { content: '\e8d7'; } /* '' */
.icon-logout:before { content: '\e8e8'; } /* '' */
.icon-newsfeed:before { content: '\e8e1'; } /* '' */
.icon-people:before { content: '\e8e2'; } /* '' */
.icon-plus:before { content: '\e8b2'; } /* '' */
.icon-profile:before { content: '\e8e3'; } /* '' */
.icon-settings:before { content: '\e8d6'; } /* '' */

// MFG
.icon-close:before { content: '\e8a7'; } /* '' */
.icon-open-down:before { content: '\f004'; } /* '' */
.icon-open-left:before { content: '\f007'; } /* '' */
.icon-open-right:before { content: '\f006'; } /* '' */
.icon-open-up:before { content: '\f005'; } /* '' */
.icon-ok:before { content: '\e8b4'; } /* '' */

// Entypo
.icon-cog:before { content: '\e8d5'; } /* '' */
.icon-pencil:before { content: '\e8b9'; } /* '' */

// Elusive
.icon-eye:before { content: '\e803'; } /* '' */
.icon-eye-off:before { content: '\e805'; } /* '' */
