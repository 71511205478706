.visible-sm {
  display: none!important;

  @media ($under-screen-medium) {
    display: block!important;
  }
}

.visible-xs {
  display: none!important;

  @media ($under-screen-small) {
    display: block!important;
  }
}

.visible-xxs {
  display: none!important;

  @media ($under-screen-xsmall) {
    display: block!important;
  }
}

.visible-xxxs {
  display: none!important;

  @media ($under-screen-xxsmall) {
    display: block!important;
  }
}

.hidden-sm {
  @media ($under-screen-medium) {
    display: none!important;
  }
}

.hidden-xs {
  @media ($under-screen-small) {
    display: none!important;
  }
}

.hidden-xxs {
  @media ($under-screen-xsmall) {
    display: none!important;
  }
}

.hidden-xxxs {
  @media ($under-screen-xxsmall) {
    display: none!important;
  }
}
