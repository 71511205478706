.community__widget {
  margin-bottom: 35px;
  overflow-wrap: break-word;
  word-wrap: break-word;
  word-break: break-word;

  a {
    transition: all 0.5s ease;
  }

  &__event {
    display: flex;
    margin-bottom: 15px;
    &:last-of-type {
      margin-bottom: 0;
    }

    &__calendar {
      margin-right: 10px;
    }

    &__calendar-page {
      @include title-small;
      border: $grey-line;
      background-color: $color-blue-background;
      color: $color-blue;
      text-transform: uppercase;
      text-align: center;
      width: 42px;
      height: 42px;
      font-size: 13px;
      line-height: 16px;
      justify-content: center;
      display: flex;
      flex-direction: column;
    }

    &__content {
      &-title {
        &, a {
          @include title-small;
        }
      }

      &-subtitle {
        @include text-light;
      }

      .icon {
        color: $color-grey-dark;
        margin-left: 3px;
      }
    }
  }

  &__pinned-post {
    margin-bottom: 15px;
    overflow: hidden;

    &:hover .community__widget__pinned-post__title, &:focus .community__widget__pinned-post__title {
      color: $color-grey-dark;
    }

    &__avatar {
      float: left;
      display: block;
    }

    &__title {
      @include title-small;
      float: left;
      display: block;
      width: calc(100% - 40px);
      padding: 6px 0 0 10px;
      transition: all 0.5s ease;
    }
  }

  &__popular-post {
    margin-bottom: 15px;
    overflow: hidden;

    &__avatar {
      float: left;
      display: block;
    }

    &__title {
      @include title-small;
      float: left;
      display: block;
      width: calc(100% - 40px);
      padding: 6px 0 0 10px;
    }
  }
}
